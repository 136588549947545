<template>
<div class="flex flex-col p-3 pt-6 sm:p-6">
    <Khead />
    <KJobFailedMessage/>
</div>
</template>

<script>
import Khead from '@/components/partials/Khead'
import KJobFailedMessage from '@/components/purchasesComponents/KJobFailedMessage'

export default {

    data() {
        return {
            
        }
    },
    
    components:{
        Khead,
        KJobFailedMessage
    },
    
    computed: {
    },
    mounted() {
    },
}
</script>

<style scoped>

</style>